@import './variables';
@import './mixins';
@import './layout';

// Body
body {
	font-family: "Helvetica Neue";
	color: $color-grey-dark;
}

// Root
#root {
	@extend .scale-to-fill, .a-col-stretch;
}

// Header
.header {
	@extend .a-row;
	flex-wrap: wrap;
	span {
		font-size: $font-30;
	}
}

.a-header-wrapper {
	height: 50px !important;
}
.main-header {
	@extend .a-row-center;
	justify-content: flex-start;
	padding: 1.25rem;
	height: 50px;

	.app-title {
		font-size: $font-16;
		font-weight: $font-medium;
		line-height: $font-30;
	}
}

// Footer
.footer {
	@extend .a-row-center;
	height: 40px;
	min-height: 40px;
	flex-wrap: wrap;
	span {
		font-size: $font-12;
		color: $color-grey;
	}
}

// Content Wrapper
.content-wrapper {
	@extend .a-col;

	& > div {
		padding: 1rem 2rem;
		width: 100%;
		height: 100%;
	}

	flex: auto;
	overflow: auto;
}

hr {
	margin: 0;
}

//Href
a {
	color: initial;
	background-color: initial;
}
a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
}

//Devider
.divider {
	display: inline-block;
  height: 1.25rem;
  width: .0625rem;
  background-color: #eee;
  margin: 0 1.25rem;
}
.devider {
	width: 100%;
	height: .5px;
	border-color: $color-red;
}

//Text
.page-title {
	font-size: $font-40;
	font-weight: $font-bold;
}

// Loading
.loading-wrapper {
	@extend .scale-to-fill, .a-col-center, .upfront;
}

// Checkbox
.checkbox-zone {

}

.checkbox-zone-checked {
	background-color: $color-red;
}

.checkbox-zone-focused {
	background-color: $color-grey;
}

.a-checkbox-wrapper {
	.a-checkbox-text {
		color: $color-white;
		font-size: $font-25;
		line-height: $font-25;
	}

	.a-checkbox-inner {
		width: 1.5rem;
		height: 1.5rem;
		border-color: $color-white !important;
		.appkiticon {
			color: $color-white !important;
		}
	}
}

// Input
.input-base {
	text-align: center;
	color: inherit;
	background-color: inherit;
	border-bottom: 2px solid $color-white;
	width: 35rem;
	align-self: center;
}

.a-input-text[readonly] {
	color: $color-grey !important;
	cursor: not-allowed;
}

.a-input-number-wrapper .a-input-number.a-input-text:disabled {
	color: $color-grey !important;
}

.labelled-number-input {
	display: inline-flex;
	align-items: center;
	margin-bottom: 1rem;

	.label-zone {
		line-height: 1rem;
  	margin-right: 1.25rem;
  	flex: 0 0 auto;
  	width: 160px;
  	text-align: right;

		.label-title {
			font-size: $font-14;
  		margin-right: 5px;
		}

		.label-unit {
			font-size: $font-12;
			color: $color-grey;
		}
	}
}

.under-labelled-number-input {
	@extend .a-col-center;

	.label-zone {
		line-height: 1rem;
		width: 100%;

		.label-title {
			font-size: $font-14;
  		margin-right: 3px;
		}

		.label-unit {
			font-size: $font-12;
			color: $color-grey;
		}
	}
}


// Radio
.a-radio-disabled+.a-radio-text {
	color: $color-grey !important;
}

button {
	font-size: $font-14 !important;
	font-weight: $font-medium !important;
	line-height: $font-21 !important;
	height: 34px !important;
}
.button-disabled {
	color: $color-disabled !important;
	background-color: $color-bg-disabled !important;
}

// Progress
.a-progress {
	height: 1.5rem !important;
}
.a-progress .a-progress-bar:before {
	display: none;
}

// Modal
.a-modal .modal-dialog {
	.modal-header {
		display: flex;
	  align-items: center;
	  justify-content: space-between;
	  width: 100%;
	  .modal-title {
	  	position: absolute;
	  	margin-left: -1.25rem;
	  	width: 100%;
	  	font-size: $font-18;
	  	font-weight: $font-bold;
	  	text-align: center;
	  }
	  .a-close-btn {
	  	z-index: 3;
	  }
	}

	.modal-body {

	}

	.modal-footer {
	  border-top: 1px solid $color-grey-light;
	}
}

.a-modal .modal-dialog.confirm-modal {

}

.a-modal .modal-dialog.alert-modal {

}

.a-modal .modal-dialog.info-modal {
	.modal-footer {
		border-top: 0;
	}
}

.a-modal .modal-dialog.round-timeout-modal {
	width: 350px;

	.modal-body {
		.main-area {
			@extend .a-col-center;
			@include flex-gap(1rem, 0rem);

			.banner-icon {
				color: $color-orange-light;
				font-size: $font-32 !important;
			}
			
			.title {
				font-size: $font-16;
				line-height: $font-24;
				font-weight: $font-bold;
			}

			.messages {
				@extend .a-col-center;
				font-size: $font-14;
				line-height: $font-21;
				font-weight: $font-regular;
			}

			button {
				height: 34px !important;
				width: 178px !important;
			}
		}
	}
}

.a-modal .modal-dialog.round-submitted-modal {
	width: 522px;
	height: 375px;

	.modal-body {
		padding: 2rem;
		border-radius: 2px;
		background-color: $color-tangerine;
		.main-area {
			@extend .a-col-stretch;
			@include flex-gap(1rem, 0rem);
			color: $color-white;
			font-weight: $font-medium;

			.banner-icon {
				color: $color-orange-light;
			}
			
			.title {
				width: 350px;
				font-size: $font-40;
				// font-weight: $font-medium;
				line-height: $font-60;
			}

			.messages {
				@extend .a-col-align-start;
				font-size: $font-16;
				// font-weight: $font-medium;
				line-height: $font-24;
			}

			.button-group {
				@extend .a-row-center;
				button {
					height: 34px !important;
					width: 178px !important;
				}
			}

			.player-icon {
				width: 30px;
				height: 30px;
				margin-right: 10px;
			}

			.round-clock {
				@extend .a-row-center;
				background-color: $color-white;
				margin-bottom: 5px;
				width: 108px;
				height: 54px;
				border-radius: 2px;
				box-shadow: $box-shadow-medium;
				font-size: $font-14 !important;
				font-weight: $font-regular !important;
				color: $color-grey-dark;

				.separator {
					margin-left: .5rem;
					margin-right: .5rem;
				}

				.minute,
				.second {
					@extend .a-row-center;
					width: 34px;
					height: 34px;
					background-color: $color-grey-light;
				}
				.second-warning {
					background-color: $color-rose-light;
				}
			}
		}
	}
}

.a-modal .modal-dialog.round-waiting-modal {
	width: 522px;
	height: 325px;

	.modal-body {
		padding: 2rem;
		border-radius: 2px;
		background-color: $color-tangerine;
		.main-area {
			@extend .a-col-align-start;
			@include flex-gap(1rem, 0rem);
			color: $color-white;
			font-weight: $font-medium;

			.banner-icon {
				color: $color-orange-light;
			}
			
			.title {
				width: 350px;
				font-size: $font-40;
				line-height: $font-60;
			}

			.messages {
				@extend .a-col-align-start;
				font-size: $font-16;
				line-height: $font-24;
			}

			.player-icon {
				width: 30px;
				height: 30px;
				margin-right: 10px;
			}
		}
	}
}

.a-modal .modal-dialog.quarter-market-report-modal {
	// max-width: 631px;
	// height: 482px;
}

.a-modal .modal-dialog.audit-report-modal {
	max-width: 673px;

	.view-impact-zone {
		position: relative;

		& > :nth-child(1) {
			font-size: $font-22;
			line-height: $font-36;
			font-weight: $font-medium;
			margin-bottom: 1rem;
		}

		.overlay-wrapper {
			align-items: flex-start;
			padding-top: 1rem;
			.overlay {
				@include flex-gap(.75rem, 0rem);
				width: 350px;

				.banner-icon {
					color: $color-orange-light;
					font-size: $font-32 !important;
				}
				
				.title {
					font-size: $font-16;
					line-height: $font-24;
					font-weight: $font-bold;
				}

				.messages {
					@extend .a-col-center;
					font-size: $font-14;
					line-height: $font-21;
					font-weight: $font-regular;
					text-align: center;
				}

				button {
					height: 34px !important;
					width: 178px !important;
				}
			}
		}

		.view-impact-bias-metrics-container {
			@extend .a-row;
			align-items: flex-start;
			width: 100%;

			.view-impact-bias-metrics-col {
				@extend .a-col;
				width: 50%;
			}
			.view-impact-bias-metrics-col.view-impact-for-bias {
				.view-impact-selections {
					@extend .a-col-align-start;
				}
			}
		}

		.view-impact-thresholds {
			margin-top: 1rem;

			.view-impact-thresholds-title {
				font-size: $font-22;
				line-height: $font-36;
				font-weight: $font-medium;

				.view-impact-thresholds-title-marked {
					color: $color-orange;
				}
			}

			.view-impact-thresholds-slider {
				margin-top: 1rem;
				width: 100%;

				.adjust-thresholds-panel {
					padding: 1rem;

					.main-area {
						.head-zone {
							width: 100%;
							display: flex;
							align-items: center;
							position: relative;

							& > :first-child {
								& > :first-child {
									font-size: $font-20;
									font-weight: $font-medium;
									display: block;
								}
							}

							& > :last-child {
								position: absolute;
								right: 0;
								height: 2rem;
								background-color: transparent;
								color: $color-orange;
							}
						}

						.recommendation-zone {
							color: $color-grey;
							font-size: $font-12;
							margin: 1rem 0;
			
							.categories-row {
								@extend .a-row;
								justify-content: flex-start;
								flex-wrap: wrap;
								column-gap: 2rem;
							}
						}
			
						.adjust-zone {
							@extend .a-row;
							row-gap: 1rem;
							flex-wrap: wrap;
			
							& > div {
								width: 45%;
								.labelled-number-input {
									@extend .a-row;
									.label-zone {
										width: 140px;
										text-align: left;
			
										.label-title {
											font-size: $font-12 !important;
										}
									}
			
									.a-input-number-wrapper {
										width: 40px;
			
										.a-input-number {
											padding: 0;
											font-size: $font-12;
											font-weight: $font-medium;
										}
			
										button {
											display: none;
										}
									}
								}
							}
						}	
					}
				}
			}

			.view-impact-thresholds-action {
				margin-top: 1rem;	
				width: 100%;
				display: flex;
				align-items: center;
				position: relative;

				p {
					margin: 0;
					font-size: $font-14;
					line-height: $font-21;
				}

				p:last-child {
					font-weight: $font-bold;
				}


				.view-impact-thresholds-action-update-button {
					position: absolute;
					right: 0;
					height: 2rem;
					background-color: transparent;
					color: $color-orange;
					border: 0.0625rem solid $color-orange;
				}
			}
		}

		.view-impact-bar-chart {
			margin-top: 2rem;
			.view-impact-bar-chart-title {
				font-size: $font-18;
				line-height: $font-30;
			}
		}

		.view-impact-bar-chart-group {
			margin-top: 3.5rem;

			.view-impact-bar-chart-group-container {
				width: 100%;
				display: flex;
				position: relative;

				.view-impact-bar-chart-group-title {
					font-size: $font-18;
					line-height: $font-30;
					position: relative;
					top: -$font-30;
				}

				.view-impact-bar-chart-group-chart {
					margin-top: -$font-30;
				}

				.view-impact-bar-chart-group-profit {
					display: block;
					font-size: $font-30;
					font-weight: $font-medium;
					text-align: center;
				}

				.view-impact-bar-chart-group-increase {
					display: block;
					text-align: center;
				}

				& > :first-child {
					// background-color: $color-grey-lighter;
					width: calc(70% - 1rem);
					margin-right: 1rem;
				}
			}
		}

		.view-impact-average {
			margin-top: 0.5rem;
			display: flex;
			position: relative;
			align-items: center;

			& > :first-child {
				width: 1rem;
				height: 1rem;
				background-color: #FFA929;
				margin-right: 0.5rem;
			}
		}
	}

	.view-impact-selection-zone {

		.view-impact-selection-unselected-button {
			margin-top: 0.5rem;
			height: 2rem;
			margin: 0 0.5rem 0.5rem 0;
			padding: 0 1.5rem;
		}
		
		.view-impact-selection-selected-button {
			margin-top: 0.5rem;
			height: 2rem;
			margin: 0 0.5rem 0.5rem 0;
			padding: 0 1.5rem;
		}

		& > :nth-child(1) {
			font-size: $font-18;
			line-height: $font-27;
			font-weight: $font-medium;
			display: block;
		}

		& > :nth-child(2) {
			font-size: $font-14;
			line-height: $font-21;
			margin-bottom: 1rem;

			& > :nth-child(2) {
				font-weight: $font-bold;
				color: $color-orange;
			}
		}
	}

	.bar-chart-container {
		width: 100%;
		// height: 80%;
		display: flex;
		flex-wrap: wrap;
		background-color: $color-grey-lighter;
		margin-top: 0.2rem;
		
		.bar-chart {
			height: 130px;
			width: calc(50% - 2rem);
			min-width: calc(50% - 2rem);
			position: relative;
			margin: 1rem;
			margin-bottom: 0;

			.bar-chart-legend {
				display: block;
				width: 100%;
				& > :first-child {
					position: relative;
					top: -1.5rem;
					padding: 10px;
					float: left;
				}

				& > :last-child {
					position: relative;
					top: -1.5rem;
					padding: 10px;
					float: right;
				}
			}
		}
	}
}

.a-modal .modal-dialog.adjust-thresholds-modal {
	max-width: 720px;
	.modal-body {
		.main-area {
			.head-zone {
				@extend .a-col-align-start;
				border-bottom: 1px solid $color-grey;
				padding-bottom: 1rem;
				margin-bottom: 1rem;

				.title {
					font-size: $font-14;
					font-weight: $font-bold;
					margin-bottom: .5rem;
				}
				.sub-title {
					font-size: $font-12;
				}
				.pgNbm {
					font-size: $font-12;
					font-weight: $font-bold;
				}
			}

			.recommendation-zone {
				color: $color-grey;
				font-size: $font-12;
				margin-bottom: 1rem;

				.categories-row {
					@extend .a-row;
					@include flex-gap(0rem, 2rem);
					justify-content: flex-start;
					flex-wrap: wrap;
				}
			}

			.adjust-zone {
				@extend .a-row;
				@include flex-gap(1rem, 0rem);
				flex-wrap: wrap;

				& > div {
					width: 45%;
					.labelled-number-input {
						@extend .a-row;
						.label-zone {
							width: 140px;
							text-align: left;

							.label-title {
								font-size: $font-12 !important;
							}
						}

						.a-input-number-wrapper {
							width: 40px;

							.a-input-number {
								padding: 0;
								font-size: $font-12;
								font-weight: $font-medium;
							}

							button {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.modal-footer {
		.button-comp {
			button {
				width: 70px !important;
				height: 34px !important;
			}
			& > :first-child {
				margin-right: 1rem;
			}
		}
	}
}

//Overlay
.overlay-wrapper {
	@extend .a-row-center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-overlay-wrapper-light;

	.overlay {
		@extend .a-col-center;
		box-shadow: $box-shadow-medium;
		padding: 1rem 2rem;
		background-color: $color-white;
	}
}

// Colors
$color-orange-darker: #571F01;
$color-orange-dark: #933401;
$color-orange: #D04A02;
$color-orange-light: #FD6412;
$color-orange-lighter: #FEB791;

$color-tangerine-darker: #714300;
$color-tangerine-dark: #AE6800;
$color-tangerine: #EB8C00;
$color-tangerine-light: #FFA929;
$color-tangerine-lighter: #FFDCA9;

$color-yellow-darker: #855F00;
$color-yellow-dark: #C28A00;
$color-yellow: #FFB600;
$color-yellow-light: #FFC83D;
$color-yellow-lighter: #FFECBD;

$color-red-darker: #741910;
$color-red-dark: #AA2417;
$color-red: #E0301E;
$color-red-light: #E86153;
$color-red-lighter: #F7C8C4;

$color-rose-darker: #6E2A35;
$color-rose-dark: #A43E50;
$color-rose: #D93954;
$color-rose-light: #E27588;
$color-rose-lighter: #F1BAC3;

$color-black: #000000;
$color-grey-darker: #141414;
$color-grey-dark: #2D2D2D;
$color-grey-medium: #464646;
$color-grey: #7D7D7D;
$color-grey-light: #DEDEDE;
$color-grey-lighter: #F2F2F2;
$color-white: #FFFFFF;

$color-purple-darker: #4B20AB;
$color-purple-dark: #6B2CDA;
$color-purple: #8E34F4;
$color-purple-light: #B056F6;
$color-purple-lighter: #DCB4FC;

$color-blue-darker: #003DAB;
$color-blue-dark: #0060D7;
$color-blue: #0089EB;
$color-blue-light: #4DACF1;
$color-blue-lighter: #B3DCF9;

$color-green-darker: #175C2C;
$color-green-dark: #2C8646;
$color-green: #4EB523;
$color-green-light: #86DB4F;
$color-green-lighter: #C4FC9F;

$color-info: #0089EB;
$color-error: #C52A1A;
$color-warning: #FFBF1F;
$color-success: #22992E;
$color-highlight: $color-orange;

$color-disabled: #9E9E9E;

$color-bg-disabled: #eee;

$color-risk-none: #1BBE16;
$color-risk-low: #1BBE16;
$color-risk-medium: #F7B500;
$color-risk-high: #E02020;

$color-overlay-wrapper-dark: rgba(0, 0, 0, .3);
$color-overlay-wrapper: rgba(100, 100, 100, .3);
$color-overlay-wrapper-light: rgba(255, 255, 255, .3);

@import 'app/scss/index';

.finish-page {
	@extend .a-col-stretch;
	justify-content: flex-start;

	.finish-summary {
		margin-top: 2rem;
		margin-bottom: 1rem;
		@extend .a-col-align-start;

		& > :first-child {
			@extend .a-row;

			font-size: $font-28;
			line-height: $font-44;
			font-weight: $font-medium;

			img.trophy {
				width: 18px;
				height: 18px;
				margin-right: .5rem;
			}
		}

		& > :last-child {
			font-size: $font-16;
			line-height: $font-24;
		}
	}

	.finish-cw {
		& > :first-child {
			margin-bottom: 1rem;
		}

		.title {
			font-size: $font-22;
			line-height: $font-36;
			font-weight: $font-medium;
		}

		.messages {
			display: grid;
			font-size: $font-14;
			line-height: $font-21;
		}
	}

	.finish-wealth {
		margin-top: 2rem;

		& > :first-child {
			margin-bottom: 1rem;
		}

		.title {
			font-size: $font-22;
			line-height: $font-36;
			font-weight: $font-medium;
		}

		.messages {
			font-size: $font-14;
			line-height: $font-21;
		}

		.wealth-calculation {
			margin-top: 2rem;
			border: 1px solid $color-grey-light;
			border-radius: 2px;
			padding: 1rem;

			& > * {
				margin-top: .75rem;
			}

			.title {
				font-size: $font-18;
				line-height: $font-27;
				font-weight: $font-bold;
			}

			.item-row {
				@extend .a-row;
				font-size: $font-14;
				line-height: $font-21;
			}

			.item-row:not(:first-child) {
				.item-value {
					font-weight: $font-bold;
				}
			}

			.item-row.cost-row {
				.item-value {
					color: #C52A1A;
				}
			}
			.item-row.revenue-row {
				.item-value {
					color: #22992E;
				}
			}

			.devider {
				border-color: $color-grey;
			}
		}
	}

	.performers {
		@extend .a-row;
		@include flex-gap(1rem, 1rem);
		justify-content: flex-start;
		flex-wrap: nowrap;
		align-items: baseline !important;
	}

	.performer-card {
		@extend .a-col-align-start;
		width: 211px;
		height: 125px;
		padding: 1rem;
		box-shadow: $box-shadow-medium;
		border-radius: 2px;
		margin-top: 0 !important;

		.appkiticon {
			margin-right: .5rem;
			color: $color-rose;
		}

		& > :first-child {
			font-size: $font-18;
			line-height: $font-27;
		}

		& > :last-child {
			font-size: $font-32;
			line-height: $font-48;
			font-weight: $font-medium;
		}

		img.trophy {
			width: 18px;
			height: 18px;
			margin-right: .5rem;
		}
	}

	.performer-card.error-performer {
		& > :last-child {
			color: $color-error;
		}
	}

	.performer-desc {
		width: 211px;
		color: #888888;
		font-size: $font-12;
		font-weight: $font-medium;
		line-height: $font-18;
		margin-top: 1rem;
		word-wrap: break-word;
		word-break: normal;

		span {
			display: block;
		}

		& > :not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}

	.headmast-wrapper {
		margin: -1rem -2rem;
		height: 167px;
		background-image: url('../../../assets/images/result_banner.png');
		background-repeat: no-repeat;
		background-position: left top;
		background-size: 100% 167px;
		color: $color-white;

		.headmast {
			@extend .a-row;
			height: 100%;
			padding: 1rem 2rem;
			font-size: $font-14;

			& > :first-child {
				@extend .a-col-align-start;

				& > :first-child {
					font-size: $font-40;
					font-weight: $font-bold;
				}

				& > :last-child {
					& > :last-child {
						font-weight: $font-bold;
					}
				}
			}

			& > :last-child {
				@extend .a-col-align-end;
				box-shadow: $box-shadow-medium;
				border-radius: 5px;
				align-self: center;
				background-color: $color-white;
				padding: .5rem;

				.button-group {
					@extend .a-row-center;
					height: 34px !important;
					button {
						height: 34px !important;
						width: 64.25px !important;
					}
				}

				& > :nth-child(2) {
					& > :first-child {
						font-weight: $font-bold;
					}
				}	

				& > :last-child {
					.player-icon {
						padding-right: 3px;
					}
				}
			}
		}
	}

	.bar-chart-container {
		width: 100%;
		// height: 80%;
		display: flex;
		flex-wrap: wrap;
		background-color: $color-grey-lighter;
		margin-top: 0.2rem;
		
		.bar-chart {
			height: 130px;
			width: calc(50% - 2rem);
			min-width: calc(50% - 2rem);
			position: relative;
			margin: 1rem;
			margin-bottom: 0;

			.bar-chart-legend {
				display: block;
				width: 100%;
				& > :first-child {
					position: relative;
					top: -1.5rem;
					padding: 10px;
					float: left;
				}

				& > :last-child {
					position: relative;
					top: -1.5rem;
					padding: 10px;
					float: right;
				}
			}
		}
	}

	.bias-summary {
		margin-top: 2rem;

		.title {
			font-size: $font-22;
			line-height: $font-36;
			font-weight: $font-medium;
		}

		.bias-summary-tab-content {
			margin-top: 1rem;

			.bias-summary-tab-content-title {
				font-size: $font-18;
				color: #565656;
			}

			.bias-summary-tab-all-content-container {
				display: flex;
				margin-top: 0.5rem;
				margin-bottom: 2rem;

				& > :first-child {
					margin-left: 0 !important;
				}

				

				.bias-summary-tab-each-content-container {
					width: 134px;
					margin: 0 16px 0 0;
					cursor: pointer;

					.shadow-box {
						box-shadow: $box-shadow-medium;
					}
	
					.orange-box {
						border: 1px solid $color-orange;
					}
	
					.bias-summary-tab-content-box {
						height: 124px;
						width: 134px;
						background-color: white;
						padding-top: 1rem;
						text-align: center;
	
						& > :first-child {
							display: block;
							font-size: $font-18;
							height: 27px;
						}
						
						& > :last-child {
							display: block;
							font-size: $font-32;
							margin-top: 1.5rem;
						}
					}
				}	
			}
		}
	}

	.finish-chart {
		height: 250px;
		width: 900px;
		background-color: #F2F2F2;
		margin-bottom: 1rem;

		.finish-chart-average {
			margin-top: 0.5rem;
			display: flex;
			position: relative;
			align-items: center;
			top: -10px;

			& > :first-child {
				width: 1rem;
				height: 1rem;
				background-color: #FFA929;
				margin-right: 0.5rem;
				margin-left: 100px;
			}
		}
	}
}

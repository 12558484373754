// Font Weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

// Font Size
$font-8: .5rem;
$font-10: .625rem;
$font-12: .75rem;
$font-14: .875rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-20: 1.25rem;
$font-21: 1.3125rem;
$font-22: 1.375rem;
$font-24: 1.5rem;
$font-25: 1.5625rem;
$font-27: 1.6875rem;
$font-28: 1.75rem;
$font-30: 1.875rem;
$font-32: 2rem;
$font-34: 2.125rem;
$font-36: 2.25rem;
$font-38: 2.375rem;
$font-40: 2.5rem;
$font-44: 2.75rem;
$font-45: 2.8125rem;
$font-48: 3rem;
$font-50: 3.125rem;
$font-55: 3.4375rem;
$font-60: 3.75rem;

// Font Face
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-regular;
  src:
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(../assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: $font-regular;
  src:
    local('Montserrat Italic'),
    local('Montserrat-Italic'),
    url(../assets/fonts/Montserrat-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-medium;
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'),
    url(../assets/fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: $font-medium;
  src:
    local('Montserrat MediumItalic'),
    local('Montserrat-MediumItalic'),
    url(../assets/fonts/Montserrat-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-bold;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url(../assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: $font-bold;
  src:
    local('Montserrat BoldItalic'),
    local('Montserrat-BoldItalic'),
    url(../assets/fonts/Montserrat-BoldItalic.ttf) format('truetype');
}

// Row
.a-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.a-row-center {
	@extend .a-row;
	justify-content: center;
}

.a-row-stretch {
	@extend .a-row;
	align-items: stretch;
}

// Col
.a-col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.a-col-center {
	@extend .a-col;
	justify-content: center;
}

.a-col-align-start {
	@extend .a-col;
	align-items: flex-start;
}

.a-col-align-end {
	@extend .a-col;
	align-items: flex-end;
}

.a-col-stretch {
	@extend .a-col;
	align-items: stretch;
}

.scale-to-fit {
	width: 100%;
}

.aspect-to-fit {
	width: 100%;
	max-height: 100%;
}

.scale-to-fill {
	position: absolute;
	width: 100%;
	height: 100%;
}

.pointer {
	cursor: pointer;
}

.upfront {
	z-index: 9999;
}

.hidden {
	visibility: hidden;
}